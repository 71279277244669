import { createActionGroup, emptyProps } from '@ngrx/store';

export const accountFeatureActions = createActionGroup({
  source: 'Account Feature',
  events: {
    'Load Account Information': emptyProps(),
    'Logout': emptyProps(),
    'Clear State': emptyProps(),
  },
});
