import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  document = inject(DOCUMENT);

  redirectLocationHref(url: string) {
    this.document.location.href = url;
  }

  reloadDocument(): void {
    this.document.location.reload();
  }
}
