import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AccountType } from '../account.reducer';
import { SignOutModel } from '../../../models/account/sso-sign-out.model';

export const accountApiActions = createActionGroup({
  source: 'Account Api',
  events: {
    'Loaded Account Information Successfully': props<{ account: AccountType }>(),
    'Loaded Account Information Failed': emptyProps(),
    'Logout Successfully': props<{ redirectUrl: SignOutModel['redirectUrl'] }>(),
  },
});
